/** @format */

import { cx } from '@emotion/css';
import { Box, FormControl, MenuItem, Select } from '@mui/material';
import { format } from 'date-fns';
import { Fragment, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import IconLabelButtons from 'src/components/Buttons';
import { CheckIcon, ChevronDown, CrossIcon, RightArrow } from 'src/components/Icons';
import { countriesMap, marketMap } from 'src/utils/market';

export const UserItem = ({ i, user, lastPostRef, approveUser, rejectUser, customCSS }) => {
  const { t } = useTranslation();
  const [market, setMarket] = useState(user.locale || 'en');
  const [roles, setRoles] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const allRoles = useSelector(state => state.adminReducer.roles);
  const checkNavOpen = useSelector(state => state.navigationReducer.navigationOpen);

  useEffect(() => {
    if (allRoles && market && allRoles[market]) {
      setRoles(allRoles[market].filter(role => user.roles.includes(role.name)).map(role => role.name));
    }
  }, [user.roles, market, allRoles]);

  return (
    <Fragment>
      <Box className="list-item" ref={lastPostRef}>
        <Box className="role">
          <label>{t('admin.role')}</label>
          <FormControl variant="standard">
            <Select
              size="small"
              labelId={`role-select-label-${i}`}
              id={`role-select-${i}`}
              value={roles}
              onChange={e => setRoles([e.target.value])}>
              {allRoles[market] && allRoles[market].length > 0 ? (
                allRoles[market].map((role, i) => (
                  <MenuItem key={i} value={role.name}>
                    {role.name}
                  </MenuItem>
                ))
              ) : (
                <MenuItem value={roles[0]}>{roles[0]}</MenuItem>
              )}
            </Select>
          </FormControl>
        </Box>
        <Box className="locale">
          <label>{t('admin.locale')}</label>
          <FormControl variant="standard">
            <Select
              size="small"
              labelId={`market-select-label-${i}`}
              id={`market-select-${i}`}
              value={market}
              onChange={e => setMarket(e.target.value)}>
              {Object.keys(Object.fromEntries(marketMap)).map(key => (
                <MenuItem key={key} value={key}>
                  {t(`market.${marketMap.get(key)}`)}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
        {!checkNavOpen && (
          <Box className="company">
            <label>{t('admin.company')}</label>
            <span>{user.company}</span>
          </Box>
        )}
        <Box className="l-name">
          <label>{t('admin.name')}</label>
          <span>
            {user.firstName} {user.lastName}
          </span>
        </Box>
        {!checkNavOpen && (
          <Box className="register-date">
            <label>{t('admin.registrations_date')}</label>
            <span>{user.created ? format(new Date(user.created * 1000), 'P') : ''}</span>
          </Box>
        )}
        {!checkNavOpen && (
          <Box className="email">
            <label>{t('admin.email')}</label>
            <span>{user.email}</span>
          </Box>
        )}
        <Box className="action">
          <label>{t('admin.action')}</label>
          <span className="buttons">
            <IconLabelButtons
              endIcon={<CheckIcon color="#000" />}
              type="button"
              className="customButton noLabel light small-tailless"
              onClick={() => {
                setIsLoading(true);
                approveUser({ user, market, roles });
              }}
              disabled={isLoading}
            />
            <IconLabelButtons
              endIcon={<CrossIcon color="#000" />}
              type="button"
              className="customButton noLabel small-tailless close"
              onClick={() => rejectUser(false, user.uuid)}
            />
          </span>
        </Box>
        <Box className="action" sx={{ position: 'absolute', right: 20 }}>
          <IconLabelButtons
            endIcon={<ChevronDown color="#000" />}
            type="button"
            className="customButton noLabel small-tailless"
            onClick={() => setIsOpen(!isOpen)}
          />
        </Box>
      </Box>
      {isOpen && (
        <div className={cx(customCSS.postDetailContainer, ' user-detail_wrapper')}>
          <Box className="role">
            <label>{t('admin.role')}</label>
            <span>{roles.join(', ')}</span>
          </Box>
          <Box className="locale">
            <label>{t('admin.locale')}</label>
            <span>{t(`market.${marketMap.get(market)}`)}</span>
          </Box>
          <Box className="company">
            <label>{t('admin.company')}</label>
            <span>{user.company}</span>
          </Box>
          <Box className="f-name">
            <label>{t('admin.first_name')}</label>
            <span>{user.firstName}</span>
          </Box>
          <Box className="l-name">
            <label>{t('admin.last_name')}</label>
            <span>{user.lastName}</span>
          </Box>
          <Box className="register-date">
            <label>{t('admin.registrations_date')}</label>
            <span>{user.created ? format(new Date(user.created * 1000), 'PPP') : ''}</span>
          </Box>
          <Box className="email">
            <label>{t('admin.email')}</label>
            <span>{user.email}</span>
          </Box>
          <Box className="drsId">
            <label>{t('admin.drsId')}</label>
            <span>{user.drsId}</span>
          </Box>
          <Box className="sealNumber">
            <label>{t('admin.sealNumber')}</label>
            <span>{user.sealNumber}</span>
          </Box>
          <Box className="vatNumber">
            <label>{t('admin.vatNumber')}</label>
            <span>{user.vatNumber}</span>
          </Box>
          <Box className="location">
            <label>{t('admin.street')}</label>
            <span>{user.address?.street}</span>
          </Box>
          <Box className="location">
            <label>{t('admin.zip')}</label>
            <span>{user.address?.postalCode}</span>
          </Box>
          <Box className="location">
            <label>{t('admin.city')}</label>
            <span>{user.address?.city}</span>
          </Box>
          <Box className="location">
            <label>{t('admin.country')}</label>
            <span>{user.address && countriesMap.get(user.address.country)}</span>
          </Box>
          <Box className="location">
            <label>{t('admin.phone')}</label>
            <span>{user.address?.phoneNumber}</span>
          </Box>
          <Box className="location">
            <label>{t('admin.fax')}</label>
            <span>{user.address?.faxNumber}</span>
          </Box>
          <Box className="comment">
            <label>{t('admin.comment')}</label>
            <span>{user.comment}</span>
          </Box>
          <Box sx={{ display: 'flex !important', justifyContent: 'flex-end' }}>
            <IconLabelButtons
              type="button"
              label={t('admin.reject')}
              className="customButton light"
              onClick={() => rejectUser(false, user.uuid)}
            />
            <IconLabelButtons
              endIcon={<RightArrow />}
              type="button"
              label={t('admin.approve')}
              className="customButton dark"
              onClick={() => {
                setIsLoading(true);
                approveUser({ user, market, roles });
              }}
              sx={{ ml: '16px' }}
              disabled={isLoading}
            />
          </Box>
        </div>
      )}
    </Fragment>
  );
};
