/** @format */

import React, { Fragment, useEffect, useState } from 'react';
import useAuth from 'src/hooks/useAuth';
import Subheader from 'src/components/Subheader';
import { useDispatch } from 'react-redux';
import { dashBoardWidgetAction } from 'src/store/action/dashboardWidgetAction';
import concat from 'lodash/concat';
import filter from 'lodash/filter';
import map from 'lodash/map';
import { ScrollAble } from 'src/components/ScrollAble';
import { Responsive, WidthProvider } from 'react-grid-layout';
import { Widgets } from 'src/constants';
import { dashboardWidgetDataAction } from 'src/store/action/dashboardWidgetDataAction';
import {
  createDashboardConfig,
  getDashboardConfig,
  updateDashboardConfig
} from 'src/store/action/dashboardConfig.action';
import { Box, Button } from '@mui/material';
import { TouchIcon } from 'src/components/Icons';
import { css, cx } from '@emotion/css';
import { getDashboards } from 'src/store/action/dashboards.action';
import { t } from 'i18next';
import RotateLeftIcon from '@mui/icons-material/RotateLeft';
import IconLabelButtons from 'src/components/Buttons';

const ResponsiveReactGridLayout = WidthProvider(Responsive);

const SubDashboard = () => {
  const { user } = useAuth();
  const [userData, setUserData] = useState(null);
  const dispatch = useDispatch();
  const [overAllWidget, setOverAllWidget] = useState([]);
  const [activeWidget, setActiveWidget] = useState([]);
  const [inactiveWidget, setInactiveWidget] = useState([]);
  const [, setDashboards] = useState([]);
  const exceptionWidgets = ['notifications', 'search', 'repairIt'];
  const [currentLayouts, setCurrentLayouts] = useState({});
  const compactType = 'vertical';
  const defaultProps = {
    className: `sub_dashbord_layout`,
    cols: { xl: 4 },
    rowHeight: 5,
    breakpoints: { xl: 1500 },
    margin: [16, 16],
    isDraggable: true, // ***** Drag
    isResizable: false //**** Resize
  };

  const preventWidgetsDroppingBelowSecondRow = key => {
    for (let i = 0; i < key.length; i++) {
      let maxY = 10;
      let totalWidth = defaultProps.cols.lg;
      if (key[i].y > maxY) {
        let fallen = key[i];
        let bottomRow = Array.from(Array(totalWidth).keys());
        for (let j = 0; j < key.length; j++) {
          let lr1 = key[j];
          if (lr1.y > 0 && lr1.i !== fallen.i) {
            for (let v = lr1.x; v < lr1.x + lr1.w; v++) {
              bottomRow[v] = null;
            }
          }
        }
        let space = [];
        for (let n = 0; n < bottomRow.length; n++) {
          if (bottomRow[n] != null) {
            space.push(bottomRow[n]);
          }
        }
        if (space.length) {
          fallen.x = space[0];
          fallen.y = 10;
        }
      }
    }
    return key;
  };

  const onDragStop = (key, layout) => {
    key = preventWidgetsDroppingBelowSecondRow(key);

    let newValueActive = map(activeWidget, (item, i) => {
      return { ...item, data_grid: key[i] };
    });
    let newValueOverAll = concat(newValueActive, inactiveWidget);

    for (let j = 0; j < newValueOverAll.length; j++) {
      if (exceptionWidgets.includes(newValueOverAll[j].i)) {
        newValueOverAll[j].status = true;
      }
    }
    setOverAllWidget(newValueOverAll);

    dispatch(
      updateDashboardConfig({
        uuid: userData.uuid,
        id: userData.activeDashboard.id,
        layout: newValueOverAll
      })
    ).then(dashboardConfig => {
      if (dashboardConfig.requestStatus === 'rejected') {
      }
    });
  };

  const onLayoutChange = (key, layouts) => {
    setCurrentLayouts(layouts);
    let stringifiedLayouts = JSON.stringify(layouts);
    dispatch(dashBoardWidgetAction(stringifiedLayouts));
    dispatch(dashboardWidgetDataAction(activeWidget));

    // **** need a real time change widget uncomment below ****
    //localStorage.setItem('layouts', JSON.stringify(layouts))
  };

  const correctDashboardConfig = (dashConfig, dashWidgets) => {
    let dashboardData = {};
    let widgetData = [];

    // making it into an object/dictionary so we can pull data from it during the for loop
    for (let j = 0; j < dashWidgets.length; j++) {
      dashboardData[dashWidgets[j].id ? dashWidgets[j].id : dashWidgets[j].i] = dashWidgets[j];
    }

    for (let i = 0; i < dashConfig.length; i++) {
      if (dashboardData[dashConfig[i].name]) {
        const widgetDataItem = { ...dashConfig[i], status: true, dashboards: dashboardData[dashConfig[i].name] };
        widgetData.push(widgetDataItem);
      }
    }

    return widgetData;
  };

  useEffect(() => {
    if (user) {
      if (user.uuid) {
        setUserData(user);
        let dashb = [];

        if (user.activeDashboard) {
          // incase you need to reset the dashboard
          // dispatch(updateDashboardConfig({ uuid: user.uuid, id: user.activeDashboard.id, layout: Widgets }))
          dispatch(getDashboards({ lang: user.locale })).then(response => {
            if (response.requestStatus === 'rejected') {
            } else {
              dashb = response.payload.widgets;
              dispatch(getDashboardConfig({ uuid: user.uuid, id: user.activeDashboard.id }))
                .then(response => {
                  if (response.requestStatus === 'rejected') {
                  } else {
                    const dashboardConfig = response.payload.layout;
                    const dashboardConfigCorrected = correctDashboardConfig(dashboardConfig, dashb);
                    setOverAllWidget(dashboardConfigCorrected);
                    setDashboards(dashb);
                  }
                })
                .catch(err => console.log(err));
            }
          });
        } else {
          dispatch(createDashboardConfig({ uuid: user.uuid, layout: Widgets })).then(response => {
            if (response.requestStatus === 'rejected') {
              setOverAllWidget(Widgets);
            } else {
              dispatch(getDashboards({ lang: user.locale })).then(response => {
                if (response.requestStatus === 'rejected') {
                  setOverAllWidget(Widgets);
                } else {
                  dashb = response.payload.widgets;
                  const dashboardConfigCorrected = correctDashboardConfig(Widgets, dashb);
                  setOverAllWidget(dashboardConfigCorrected);
                  setDashboards(dashb);
                }
              });
            }
          });
        }
      }
    }
    return () => {
      setOverAllWidget([]);
      setDashboards([]);
    };
    // eslint-disable-next-line
  }, [user]);

  useEffect(() => {
    setActiveWidget(filter(overAllWidget, ['status', true]));
    setInactiveWidget(filter(overAllWidget, ['status', false]));
    // eslint-disable-next-line
  }, [overAllWidget]);

  const handleShowWidget = widget => {
    return widget?.map(item => {
      return (
        <div key={item.i} data-grid={item.data_grid}>
          <span className="text">{item.name}</span>
          <Box sx={{ position: 'absolute', bottom: '19px', right: '18px', zIndex: 1 }}>
            <TouchIcon color="#969696" width="20" height="20" />
          </Box>
        </div>
      );
    });
  };
  const resetDashboard = () => {
    if (user && user.uuid && user.activeDashboard) {
      dispatch(updateDashboardConfig({ uuid: user.uuid, id: user.activeDashboard.id, layout: Widgets }));
      setOverAllWidget(Widgets);
      setCurrentLayouts({ xl: Widgets.map(item => item.data_grid) });
    }
  };

  const customCSS = {
    dashNavInfo: css`
      margin: 42px 0 20px;
      display: flex;
      flex-direction: column;
      font-size: 14px;
      line-height: 18px;
      letter-spacing: 0.02em;
      color: #969696;
    `
  };
  console.log(':::currentLayouts', currentLayouts);
  return (
    <Fragment>
      <Subheader title={t('dashboard.baseLayer.title')} subtitle={t('dashboard.baseLayer.subTitle')} />

      <ScrollAble className={`sub_dashboard_wrapper`} marginTop="40px">
        <ResponsiveReactGridLayout
          {...defaultProps}
          layouts={currentLayouts}
          compactType={compactType}
          onLayoutChange={(key, layouts) => onLayoutChange(key, layouts)}
          onDragStop={(key, layout) => onDragStop(key, layout)}>
          {handleShowWidget(activeWidget)}
        </ResponsiveReactGridLayout>
      </ScrollAble>

      <Box className={cx(customCSS.dashNavInfo)}>
        <Box>
          <TouchIcon width="20" height="20" color="#969696" />
        </Box>
        <Box sx={{ mt: '14px' }}>{t('dashboard.baseLayer.btmTxt')}</Box>
        <Box sx={{ mt: '14px' }}>
          <IconLabelButtons
            onClick={resetDashboard}
            className="resetBtn"
            startIcon={<RotateLeftIcon />}
            label={t('dashboard.baseLayer.btmResetTxt')}
            bgcolor="black"
          />
        </Box>
      </Box>
    </Fragment>
  );
};

export default SubDashboard;
