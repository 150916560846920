/** @format */

import { Box, Modal, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { LabelSorting } from 'src/components/LabelSorting';
import Loader from 'src/components/Loader';
import { AdminEditorHeader } from 'src/components/PageHeader';
import useAuth from 'src/hooks/useAuth';
import { navigationOpenAction } from 'src/store/action/navigationOpenAction';
import { getTopicList } from 'src/store/action/topic.Action';
import { getUserList } from 'src/store/action/user.Action';
import TopicItem from './topicItem';
import { getRolesList } from 'src/store/action/editor.Action';
import IconLabelButtons from 'src/components/Buttons';
import { adminTopicsDeleteTopic } from 'src/store/action/adminTopics.Action';

export default function AdminTopics() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { user } = useAuth();
  const userListPayload = useSelector(state => state.userReducer.userListPayload);
  const navigate = useNavigate();
  const checkNavOpen = useSelector(state => state.navigationReducer.navigationOpen);
  const topicList = useSelector(state => state.topicReducer.topicList);
  const rolesList = useSelector(state => state.editorReducer.rolesList);
  const idDeleteTopic = useSelector(state => state.adminTopicsReducer.idDeleteTopic);
  const [sortBy, setSortBy] = useState({ sortBy: 'created', sort: 'DESC' });
  const [actionMenu, setActionMenu] = useState(null);

  useEffect(() => {
    dispatch(getUserList(userListPayload));

    if (topicList && topicList.length === 0) {
      const payload = {
        lang: user ? user.locale : 'en'
      };
      dispatch(getTopicList(payload));
    }

    if (rolesList && rolesList.length === 0) {
      const payload = {
        lang: user ? user.locale : 'en'
      };
      dispatch(getRolesList(payload));
    }
  }, [dispatch, rolesList, topicList, user, userListPayload]);

  useEffect(() => {
    if (!user.isAdmin) {
      navigate('/dashboard');
    }

    dispatch(navigationOpenAction(false));
    // eslint-disable-next-line
  }, []);
  return (
    <div className={`${checkNavOpen ? `admin-topics-nav-open` : ``} admin-user admin-topics`}>
      <Box className="admin-topics-header">
        <AdminEditorHeader text={t('adminTopics.heading')} />
      </Box>

      <Box marginTop="34px" className={`admin-topics`}>
        <Box className="user-list topic-list extranet-user-list">
          {/* head table */}
          <Box className="heading-column">
            <Box className="name">{t('adminTopics.name')}</Box>
            <Box></Box>
            <Box></Box>
            <Box className="displayName">{t('adminTopics.displayName')}</Box>
            {!checkNavOpen && (
              <>
                <Box className="brand">
                  <LabelSorting label={t('adminTopics.brand')} name="brand" sortBy={sortBy} setSortBy={setSortBy} />
                </Box>
                <Box className="user-group">
                  <LabelSorting
                    label={t('adminTopics.userGroup')}
                    name="userGroup"
                    sortBy={sortBy}
                    setSortBy={setSortBy}
                  />
                </Box>
              </>
            )}
            <Box className="action">{t('adminTopics.actions')}</Box>
          </Box>
          {/* end head table */}
          {topicList?.length > 0 ? (
            topicList.map(topic => {
              return (
                <TopicItem
                  key={topic.id}
                  topic={topic}
                  level={1}
                  actionMenu={actionMenu}
                  setActionMenu={setActionMenu}
                />
              );
            })
          ) : (
            <Loader />
          )}
          {actionMenu && (
            <Box
              sx={{ position: 'fixed', top: '0', bottom: '0', left: '0', right: '0', zIndex: '1' }}
              onClick={() => setActionMenu(null)}
            />
          )}
        </Box>
      </Box>

      <Modal
        open={!!idDeleteTopic}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="delete-modal">
        <Box className="delete-modal-inner">
          <Typography id="modal-modal-title" variant="h6" component="h2">
            {t('adminTopics.deletion.title')}
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            {t('adminTopics.deletion.description')}
          </Typography>
          <Box sx={{ mt: 4, justifyContent: 'flex-end', display: 'flex', gap: 2 }}>
            <IconLabelButtons
              type="button"
              label={t('adminTopics.deletion.no')}
              className="customButton dark"
              onClick={() => dispatch(adminTopicsDeleteTopic(null))}
            />
            <IconLabelButtons
              type="button"
              label={t('adminTopics.deletion.yes')}
              className="customButton light"
              onClick={() => dispatch(adminTopicsDeleteTopic(null))}
            />
          </Box>
        </Box>
      </Modal>
    </div>
  );
}
